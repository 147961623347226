<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                v-model="type"
                :items="orderTypesDropDown"
                :menu-props="{ maxHeight: '400' }"
                label="Наименование"
                dense
                :rules="[rules.required]"
                :error="hasErrorsForProperty(errors.errors, 'Type')"
                :error-messages="errorMessages(errors.errors, 'Type')"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="rk7OrderTypeCode"
                :items="rk7OrderTypeDropDown"
                :menu-props="{ maxHeight: '400' }"
                label="Тип заказа"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="minimumAmount"
                label="Минимальная сумма заказа"
                dense
                outlined
                :rules="[rules.required, rules.integerValidator]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <time-picker
                v-model="cookingTime"
                label="Время готовки"
                :rules="[rules.required]"
              ></time-picker>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <time-picker
                v-model="deliveryTime"
                label="Время доставки"
                :rules="[rules.required]"
              ></time-picker>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <time-picker
                v-model="processingTime"
                label="Время обработки"
                :rules="[rules.required]"
              ></time-picker>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="rk7OrderCategoryCode"
                :items="orderCategoryDropDown"
                :menu-props="{ maxHeight: '400' }"
                label="Категория заказа"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="rk7CurrencyCode"
                :items="currencyDropDown"
                :menu-props="{ maxHeight: '400' }"
                label="Валюта"
                dense
                :rules="[rules.required]"
                outlined
                persistent-hint
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import TimePicker from '@/components/time-picker'
import { rules } from '@/helpers/validation'
import { hasErrorsForProperty, errorMessages } from '@/helpers'

export default {
  name: 'OrderTypeForm',
  components: { TimePicker },
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
    hasErrorsForProperty,
    errorMessages
  }),
  computed: {
    ...mapFormHandlers('orderType/form', [
      'rk7OrderTypeCode',
      'rk7OrderCategoryCode',
      'rk7CurrencyCode',
      'type',
      'minimumAmount',
      'cookingTime',
      'deliveryTime',
      'processingTime'
    ]),
    ...mapGetters('dictionary', { currencyDropDown: 'currencyDropDown' }),
    ...mapGetters('dictionary', { rk7OrderTypeDropDown: 'rk7OrderTypeDropDown' }),
    ...mapGetters('dictionary', { orderCategoryDropDown: 'orderCategoryDropDown' }),
    ...mapGetters('dictionary', { orderTypesDropDown: 'orderTypesDropDown' }),
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  async mounted(){
    await this.fetchCurrencies();
    await this.fetchRk7OrderTypes();
    await this.fetchOrderCategories();
    await this.fetchOrderTypes();
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    ...mapActions('orderType/form', ['resetForm']),
    ...mapActions('dictionary', {
      fetchCurrencies: 'fetchCurrencies',
      fetchRk7OrderTypes: 'fetchRk7OrderTypes',
      fetchOrderCategories: 'fetchOrderCategories',
      fetchOrderTypes: 'fetchOrderTypes'
    }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
