<template>
  <v-menu
    v-model="toShow"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        v-model="inputVal"
        :label="label"
        :prepend-icon="icons.mdiClockTimeFourOutline"
        readonly
        dense
        outlined
        :rules="rules"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="inputVal"
      scrollable
      class="mt-4"
      @input="handleInput"
      format="24hr"
    ></v-time-picker>
  </v-menu>
</template>

<script>

import {
  mdiClockTimeFourOutline,
} from '@mdi/js'

export default {
  name: 'TimePicker',
  props: ['value', 'label', 'rules'],
  data() {
    return {
      content: this.value,
      toShow: false,
      icons: {
        mdiClockTimeFourOutline,
      },
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    handleInput(e) {
      this.toShow = false
    },
  },
}
</script>

