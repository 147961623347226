<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          :disabled="hasNotPermission('Permissions.OrderTypeCreate')"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="orderTypes"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >
      <template #[`item.type`]="{item}">
        {{ item.type }}
      </template>
      <template #[`item.minimumAmount`]="{item}">
        {{ item.minimumAmount }}
      </template>
      <template #[`item.cookingTime`]="{item}">
        {{ item.cookingTime }}
      </template>
      <template #[`item.deliveryTime`]="{item}">
        {{ item.deliveryTime }}
      </template>
      <template #[`item.processingTime`]="{item}">
        {{ item.processingTime }}
      </template>
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                :disabled="hasNotPermission('Permissions.OrderTypeUpdate')"
                v-bind="attrs"
                v-on="on"
                @click="updateDialogShow(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Редактирование</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <order-type-form :label="'Редактирование типа заказа'" :cancellation="updateDialogCancel"
                     :show="updateDialog"
                     :save="updateOrderType"></order-type-form>
    <order-type-form :label="'Создание типа заказа'" :cancellation="createDialogCancel" :show="createDialog"
                     :save="addOrderType"></order-type-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import OrderTypeForm from './order-type-form'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { hasPermission } from '@/helpers'

export default {
  components: { OrderTypeForm },
  name: 'OrderTypeList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('orderType', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('orderType', {
      orderTypes: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'type' },
        { text: 'Минимальная сумма заказа', value: 'minimumAmount' },
        { text: 'Время готовки', value: 'cookingTime' },
        { text: 'Время доставки', value: 'deliveryTime' },
        { text: 'Время обработки', value: 'processingTime' },
        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Типы заказов'
    this.fetchOrderTypes()
  },
  methods: {
    ...mapActions('orderType', {
      fetchOrderTypes: 'fetchList',
    }),
    ...mapActions('orderType/form', ['create', 'resetForm', 'fetchById', 'update']),
    hasNotPermission(val){
      return !hasPermission(val);
    },
    async addOrderType() {
      await this.create()
      this.createDialog = false
    },
    async updateOrderType() {
      await this.update()
      this.updateDialog = false
    },
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel() {
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
